import { UntitledIcon } from '@faceup/icons'
import { Modal, Text } from '@faceup/ui'
import { Avatar, Flex, Grid, Group, Stack, Title } from '@mantine/core'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import CheckIcon from './assets/check-icon.svg'
import { DotsMesh, UpgradeButtons } from './components'
import type { UpsellData } from './upsellData'

const messages = defineMessages({
  title: 'Administration.upsell.modal.title',
})

type UpsellModalProps = {
  isOpened: boolean
  onClose: () => void
  upsell: UpsellData
}

export const UpsellModal = (props: UpsellModalProps) => {
  const { isOpened, onClose, upsell } = props
  const { icon, title, description, features } = upsell

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      width={800}
      title={<FormattedMessage {...messages.title} />}
    >
      <Grid m='lg' mr='xs' gutter='md' align='center' justify='center'>
        <Grid.Col span={6}>
          <Stack spacing='21px'>
            <Stack spacing='14px'>
              <Title order={3}>{title}</Title>
              <Text>{description}</Text>
              {features.map((feature, i) => (
                // biome-ignore lint/suspicious/noArrayIndexKey:
                <Group key={`feature-${i}`} spacing={4} noWrap align='flex-start'>
                  <img width={16} height={16} src={CheckIcon} alt='' />
                  <Text>{feature}</Text>
                </Group>
              ))}
            </Stack>
            <UpgradeButtons upsell={upsell} />
          </Stack>
        </Grid.Col>
        <Grid.Col span={6} pos='relative'>
          <Flex justify='center'>
            <Avatar color='blue' size={56} style={{ zIndex: 1 }}>
              <UntitledIcon icon={icon} />
            </Avatar>
          </Flex>
          <Flex justify='center' pos='absolute' style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <DotsMesh rows={6} cols={16} />
          </Flex>
        </Grid.Col>
      </Grid>
    </Modal>
  )
}
