import type { UntitledIconData } from '@faceup/icons'

const uniqueId = 'ucFileAiGradient'

export const ucFileAi: UntitledIconData = {
  prefix: 'custom',
  name: 'ucFileAi',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M11.9983 2.00001C12.2553 1.99869 12.4563 2.16407 12.5394 2.45464C12.8023 3.37114 13.0495 4.29226 13.3276 5.20416C14.1929 8.04065 16.0626 9.86181 18.8997 10.6907C19.7657 10.9437 20.635 11.1868 21.5016 11.4365C21.8344 11.5327 21.9939 11.7067 21.9998 11.9676C22.0058 12.2345 21.8397 12.4203 21.4963 12.5197C20.488 12.8116 19.4652 13.0627 18.472 13.4C15.7937 14.3093 14.0762 16.1561 13.2874 18.8727C13.031 19.7569 12.7859 20.6444 12.5315 21.5293C12.4016 21.9807 11.9436 22.1493 11.647 21.8489C11.5554 21.7553 11.4981 21.6137 11.4599 21.4839C11.1771 20.5219 10.9287 19.5487 10.6229 18.5947C9.76351 15.9156 7.97488 14.1597 5.29787 13.3223C4.37456 13.033 3.43741 12.7866 2.50685 12.5211C2.16744 12.4229 1.99675 12.2397 2.00005 11.9709C2.00334 11.7027 2.17074 11.5327 2.51805 11.4313C3.60546 11.1157 4.71462 10.8594 5.77435 10.4686C8.32285 9.52776 9.95463 7.70199 10.7112 5.09083C10.9649 4.21452 11.2101 3.33556 11.4612 2.45859C11.5449 2.16539 11.74 2.00133 11.9983 2.00001Z',
      stroke: `url(#${uniqueId})`,
      strokeWidth: '1.4',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      fill: `url(#${uniqueId})`,
    },
  ],
  defs: [
    {
      type: 'LinearGradient',
      def: {
        id: uniqueId,
        x1: '6.93656',
        y1: '43.3542',
        x2: '35.5163',
        y2: '28.8859',
        gradientUnits: 'userSpaceOnUse',
        stops: [{ stopColor: '#0085FF' }, { offset: '1', stopColor: '#730099' }],
      },
    },
  ],
}
