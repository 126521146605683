import type { Institution, Plan } from '@faceup/utils'
import { type ReactNode, createContext } from 'react'
import type { PhoneInputProps } from 'react-phone-input-2'
import type { SubmitButtonText } from '../Form'
import type { PagesTranslationItems, PagesTranslationVariants } from '../hooks'
import '../failwind.css'

type UiProviderContextProps = {
  linkExternalConfirm?: {
    confirmRedirectMessageTitle?: string
    confirmRedirectMessageContent?: string
    redirectOkButton?: string
    redirectCancelButton?: string
  }
  inputPhone?: Pick<PhoneInputProps, 'searchPlaceholder' | 'localization' | 'searchNotFound'>
  inputCopyable?: {
    onCopySuccess?: () => void
    copy: string
  }
  pages?: {
    translations: Record<PagesTranslationVariants, Record<PagesTranslationItems, string>>
    isDefaultForm: boolean
    setIsDefaultForm: (isDefaultForm: boolean) => void

    mother: {
      type: Institution | null
      plan: Plan | null
      isTesting: boolean | null
    }
  }
  buttonConfirm?: {
    confirm: string
    cancel: string
  }
  form?: {
    submitButton: Record<SubmitButtonText, string>
  }
  modalConfirm?: {
    cancelText: string
    confirmText: string
  }
  multiSelect?: {
    allSelected: string
  }
  theme?: {
    colors?: {
      primary?: string
      dark?: string
    }
  }
  table?: {
    shown: string
  }
  dropzoneImage?: {
    upload: string
  }
  editableSortableList?: {
    addItem: string
  }
}

export type UiProviderProps = {
  children: ReactNode
} & UiProviderContextProps

export const UiContext = createContext<UiProviderContextProps>({})

const UiProvider = ({ children, ...props }: UiProviderProps) => (
  <UiContext.Provider value={props}>{children}</UiContext.Provider>
)

export default UiProvider
