import { constants, Flex, SizeContext } from '@faceup/ui-base'
import { Box } from '@mantine/core'
import { type AriaRole, type ReactNode, type Ref, forwardRef, useContext } from 'react'
import { BaseComponent, type Sx } from '../CoreComponents/BaseComponent'
import { transitionDuration } from '../constants'

type Padding = 'small' | 'default'
const paddingMap: Record<Padding, string> = {
  small: '3px',
  default: '7px',
}

export type InputSkeletonPublicProps = {
  prefix?: ReactNode
  suffix?: ReactNode
  disabled?: boolean
  variant?: 'input' | 'textarea'
}

type InputSkeletonProps = {
  children?: ReactNode
  onClick?: () => void
  cursor?: 'default' | 'pointer'
  enableSelection?: boolean
  isFocused?: boolean
  role: AriaRole
  padding?: Padding
} & InputSkeletonPublicProps

export const InputSkeleton = forwardRef((props: InputSkeletonProps, ref: Ref<HTMLDivElement>) => {
  const {
    children,
    prefix,
    suffix,
    onClick,
    cursor = 'default',
    enableSelection = false,
    isFocused = false,
    role,
    disabled = false,
    padding = 'default',
    variant = 'input',
  } = props
  const size = useContext(SizeContext) ?? 'middle'

  return (
    <BaseComponent
      ref={ref}
      onClick={onClick}
      role={role}
      tabIndex={disabled ? undefined : 0}
      sx={({ getColorFromTheme }) => {
        const sxFocused: Sx = {
          borderColor: getColorFromTheme('primary.100'),
          boxShadow: '0px 0px 0px 2px #0E9AF733',
          outline: 'none',
        }

        return {
          boxSizing: 'border-box',
          border: `1px solid ${getColorFromTheme('dark.20')}`,
          width: '100%',
          height: variant === 'input' ? constants.input.heightPx[size] : undefined,
          minHeight: variant === 'textarea' ? constants.input.heightPx[size] : undefined,
          borderRadius: '6px',
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          transition: `all ${transitionDuration}`,
          paddingInlineStart: prefix ? paddingMap.default : paddingMap[padding],
          paddingInlineEnd: paddingMap.default,
          gap: '8px',
          cursor,
          userSelect: enableSelection ? undefined : 'none',
          ':-moz-any-link': {
            fontSize: '20px',
          },

          '&:focus': sxFocused,
          '& .active:hover': {
            padding: '1rem',
            gap: '8px',
          },
          ...(isFocused ? sxFocused : {}),
        }
      }}
    >
      {prefix && (
        <Flex align='end' style={{ paddingBottom: '4px' }}>
          {prefix}
        </Flex>
      )}
      <Box
        sx={{
          flex: 1,
        }}
      >
        {children}
      </Box>
      {suffix && (
        <Flex align='end' style={{ paddingBottom: '4px' }}>
          {suffix}
        </Flex>
      )}
    </BaseComponent>
  )
})
